.my-btn {
  padding: 5px 15px;
  margin: 20px 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 54px;
  font-family: ProximaNovaA-Semibold;
  font-size: 14px;
  background: #266ff2;
  cursor: pointer;
  box-sizing: border-box;
  outline: none !important;
  border-radius: 0;
  border: none;
  line-height: inherit;
  font-stretch: 100%;
  font-family: Proxima Nova A;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 133.8%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

@media (min-width: 768px) {
  .my-btn {
    width: 236px;
    min-width: 236px;
  }
}

button:disabled,
button[disabled] {
  background-color: #a0bdf3;
}

.my-btn span {
  margin-right: 10px;
}

.big-btn {
  min-width: 200px;
  width: 100%;
}

@media (min-width: 768px) {
  .big-btn {
    min-width: 278px;
    width: 278px;
  }
}

.security-button {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.security-button-text {
  margin-left: 10px;
  font-family: Proxima Nova A;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 133.8%;
}

@media (max-width: 768px) {
  .security-button {
    margin-bottom: 20px;
  }
}

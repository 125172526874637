.flexContainer {
  display: flex;
  justify-content: center;
  position: relative;
  height: 80px;
  padding: 0 50px 0 50px;
  border-bottom: 0.3px solid #000;
  background-color: hsla(0, 0%, 100%, 0.98);
  align-items: center;
  margin: 0 0 20px 0;
}

.alignLeft {
  position: absolute;
  right: 3em;
  display: none;
}

.alignLeftMob {
  position: absolute;
  right: 1em;
  display: block;
  box-sizing: border-box;
  height: 20px;
}

.mobile-lang-selector {
  font-family: ProximaNovaA-Semibold, sans-serif;
  color: #1d1d1c;
  outline: none !important;
  box-shadow: none !important;
  font-size: inherit;
  color: #1d1d1c;
  box-sizing: border-box;
}

.mobLangOption {
  color: #1d1d1c;
  box-sizing: border-box;
  outline: none !important;
}

.alignCenter {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

body {
  padding: 0;
  font-size: 16px;
  font-family: ProximaNovaA-Semibold, sans-serif;
  color: #1d1d1c;
}

@media (min-width: 768px) {
  .alignLeft {
    display: block;
  }
  .alignLeftMob {
    display: none;
  }
}

.localeSpan {
  cursor: pointer;
}

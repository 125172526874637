.hd {
  display: none;
}

#root {
  height: 100%;
}

body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}

body,
html {
  height: 100%;
}

* {
  color: #1d1d1c;
  box-sizing: border-box;
  outline: none !important;
}

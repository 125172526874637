.footer[_ngcontent-blh-c38] {
    width: 100%;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
  }
  
  .legal-link[_ngcontent-blh-c38] {
    display: inline-block;
    color: #000;
    font-size: 8px;
    margin: 0;
    text-decoration: none;
    box-sizing: border-box;
    outline: none!important;
    background-color: transparent;
  }
  
  @media (min-width: 768px) {
    .legal-link[_ngcontent-blh-c38] {
      display: inline-block;
      color: #000;
      font-size: .8125rem;
      line-height: 1.65em;
      margin: 0 0 0 40px;
      text-decoration: none;
      box-sizing: border-box;
      outline: none!important;
      background-color: transparent;
    }
    .footer[_ngcontent-blh-c38] {
      width: 100%;
      padding: 0 25px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      box-sizing: border-box;
    }
  }
/*.StripeElement {*/
/*  height: 56px;*/
/*  padding: 20px 12px;*/
/*  width: 100%;*/
/*  color: #32325d;*/
/*  background-color: white;*/
/*  border: 1px solid transparent;*/
/*  border-radius: 0;*/
/*  -webkit-transition: box-shadow 150ms ease;*/
/*  transition: box-shadow 150ms ease;*/
/*  box-sizing: border-box;*/
/*  box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07),*/
/*    0 1px 1.5px 0 rgba(0, 0, 0, 0.05);*/
/*}*/
/*.StripeElement--focus {*/
/*  outline: none;*/
/*  box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07),*/
/*    0 0 0 4px rgba(50, 151, 211, 0.3);*/
/*}*/

/*.StripeElement--invalid {*/
/*  border-color: #fa755a;*/
/*}*/
/*.StripeElement--webkit-autofill {*/
/*  background-color: #fefde5 !important;*/
/*}*/

.main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 1em 0;
  box-shadow: 0 2px 20px 0 hsla(0, 0%, 79.2%, 0.5);
  max-width: 690px;
  padding: 30px 10px;
  border-radius: 0;
  width: 80%;
}

.ds-container {
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (min-width: 768px) {
  .ds-container {
    width: 750px;
    /*height: calc(100% - 21px);*/
    min-height: calc(100% - 21px);
  }
  .main-container {
    width: 100%;
    padding: 30px 20px;
  }
}

.ds-container-error {
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (min-width: 768px) {
  .ds-container-error {
    width: 750px;
    min-height: 100%;
  }
}

.checkout-form {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.checkout-label {
  font-size: 14px;
  font-family: ProximaNovaA-Semibold, sans-serif;
  color: #1d1d1c;
  margin-bottom: 10px;
}

.center-label {
  font-size: 14px;
  font-family: ProximaNovaA-Semibold, sans-serif;
  color: #1d1d1c;
  margin-bottom: 10px;
  text-align: center;
}

.center-label-big {
  font-family: ProximaNovaA-Semibold, sans-serif;
  color: #1d1d1c;
  margin: 5px auto;
  text-align: center;
  font-weight: 500;
  font-size: 24px;
}

.formAndFooterContainer {
  height: calc(100% - 100px);
}

.full-height {
  height: 100vh;
}

.full-height-without-header {
  height: calc(100% - 101px);
}

.header-label {
  font-family: Georgia;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  color: #000000;
  width: 80%;
  margin: 0 auto;
  display: block;
  margin-bottom: 5px;
}

@media (min-width: 768px) {
  .header-label {
    font-family: Georgia;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    width: 100%;
    margin-bottom: 5px;
  }
}

.errorLabel {
  color: #f52011;
  font-size: 14px;
  text-align: center;
  white-space: pre-line;
}

.title-blurb {
  max-width: 690px;
  margin: 0 auto;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .title-blurb {
    width: 80%;
    font-size: 16px;
  }
}

.security-bar {
  display: flex;
  justify-content: space-around;
}

@media (max-width: 768px) {
  .security-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .button-container {
    flex-direction: column;
  }
}

.instalment-title {
  max-width: 690px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 10px;
  margin-left: 30px;
  white-space: pre-line;
}

@media (max-width: 768px) {
  .instalment-title {
    width: 80%;
    font-size: 16px;
    margin: 0 auto;
  }
}

.stp-label {
  width: 100%;
}

@media (max-width: 768px) {
  .stp-label {
    margin-top: 10px;
  }
}

.bl {
  font-weight: 800;
}

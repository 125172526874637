.payment-page-wrap-container {
  height: 100%;
}
  
.ds-container-payment {
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 122px);
}
  
@media (min-width: 768px) {
  .ds-container-payment {
    width: 750px;
    height: calc(100% - 122px);
  }
}